<script>
export default {
  name: 'CreateAccount',
  props: {
    flashdata: String,
    errorCodes: Array,
  },
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
    }
  },
  methods: {
    hasErrorCode(code) {
      this.errorCodes.includes(code)
    },
  },
  computed: {
    currentPath() {
      return window.location.pathname
    },
  },
  mounted() {},
}
</script>

<style scoped lang="scss"></style>
